exports.version = '0.1.0';
exports.dataVersion = '0.3.0';
exports.YEARLY_REPORT = 'yearly';
exports.MONTHLY_REPORT = 'monthly';


exports.CDN = 'https://d2ukhl28zq7zew.cloudfront.net';
exports.siteUrl = 'https://www.visualization.bike';
const en = 'en';
const zhHant = 'zh-hant';
const zhHans = 'zh-hans';
exports.defaultLocale = en;
exports.zhHant = zhHant;
exports.zhHans = zhHans;

exports.locales = {
    en: 'English',
    'zh-hant': '繁體中文',
    'zh-hans': '简体中文',
};

exports.OVERVIEW_PAGE = 'OVERVIEW_PAGE';
exports.ACTIVITY_PAGE = 'ACTIVITY_PAGE';
exports.OD_PAGE = 'OD_PAGE';
exports.INDEX_PAGE = 'INDEX_PAGE';
exports.ABOUT_PAGE = 'ABOUT_PAGE';
exports.ERROR_PAGE_404 = 'ERROR_PAGE_404';
exports.ogUrl = '/images/og.png';

exports.companyName = {
    'zh-hant': '共享單車視覺化',
    'zh-hans': '共享单车视觉化',
    en: 'Bike Visualization',
};


exports.ciaociaoId = {
    [en]: 'eoWEq718gm',
    [zhHant]: 'cFLBqKL14X',
    [zhHans]: 'cFLBqKL14X',
};
