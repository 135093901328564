import enLocales from './en/messages';
import zhHantLocales from './zh-hant/messages';
import zhHansLocales from './zh-hans/messages';

const getUserLocaleToLinguiLocale = (locale) => {
    if (locale === 'zh-hant') {
        return {
            locale: 'zh-hant',
            messages: zhHantLocales,
        };
    }
    return {
        locale: 'en',
        messages: enLocales,
    };
};

export default {
    'zh-hant': zhHantLocales.messages,
    'zh-hans': zhHansLocales.messages,
    en: enLocales.messages,
};
