import * as Sentry from "@sentry/nextjs";
import { version, dataVersion } from './src/utils/static';

const SENTRY_DSN = process.env.SENTRY_DSN;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: SENTRY_DSN,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 1.0,
        release: `${version}@${dataVersion}`,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}